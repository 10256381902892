import { createAtom } from "/js/vendor/xoid.custom.js";

export { default as isEqual } from 'fast-deep-equal/es6'
export { default as queryString } from 'query-string'
export { default as Cookies } from 'js-cookie'
export { throttle, debounce } from 'throttle-debounce'


export function setValue(obj, path, value) {
	var a = Array.isArray(path) ? path : path.split('.')
	var o = obj
	while (a.length - 1) {
		var n = a.shift()
		if (!(n in o)) o[n] = {}
		o = o[n]
	}
	o[a[0]] = value
}

export function getValue(obj, path) {
	var a = Array.isArray(path) ? path : path.split('.')
	var o = obj
	while (a.length - 1) {
		var n = a.shift()
		o = o[n]
	}
	o[a[0]] = value
}

export function flatToHierarchy(flat) {
	const roots = [],
		map = [],
		id = [];

	flat.forEach(item => {
		map.push(Object.assign({}, item)); // копируем
		id.push(item.id);
	});

	let i;
	map.forEach(item => {
		if (!item.parent || (i = id.indexOf(item.parent)) === -1) {
			roots.push(item);
			return;
		}
		if (map[i].children) {
			map[i].children.push(item);
		}
		else {
			map[i].children = [item];
		}
	});
	return roots;
}

export function uniqueId (length=16) {
	return Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", "")
}

export function isUUID (str) {
	let result = str.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
	return !!result && result.length === 1
}


export function isEmptyObject (obj) {
	if (obj) {
		for (var prop in obj) {
			if (Object.hasOwn(obj, prop)) {
				return false;
			}
		}
	}
	return true;
}


// Переменные для определения текущего размера экрана
export const mqBreakPoints = {
	smartphoneMax: 479.98,
	screenXsMin: 480,
	screenXsMax: 575.98,
	screenSmMin: 576,
	screenSmMax: 719.98,
	screenMdMin: 720,
	screenMdMax: 991.98,
	screenLgMin: 992,
	screenLgMax: 1199.98,
	screenXlMin: 1200,
	screenXlMax: 1599.98,
	screenXXlMin: 1600,
}

export const mqMatches = createAtom({
	smartphoneMax: false,
	screenXsMin: false,
	screenXsMax: false,
	screenSmMin: false,
	screenSmMax: false,
	screenMdMin: false,
	screenMdMax: false,
	screenLgMin: false,
	screenLgMax: false,
	screenXlMin: false,
	screenXlMax: false,
	screenXXlMin: false
});

// Текущий Breakpoint храним в mqMatches
for (let bp in mqBreakPoints) {
	let minmax = (bp.indexOf('Max') !== -1) ? 'max' : 'min';
	enquire.register('('+minmax+'-width: '+mqBreakPoints[bp]+'px)', {
		match: function () { mqMatches.focus(bp).set(true) },
		unmatch: function(){	mqMatches.focus(bp).set(false)	}
	})
};
